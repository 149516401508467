* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #4b4b4b;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #f8f9fb;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  scrollbar-width: 1px; 
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes nav {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  * {
    scrollbar-width: none;
    font-size: 1rem;
    font-weight: 300;
  }
 
}
